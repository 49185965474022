import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { zodUndefinedIfInvalid } from '@digital-magic/react-common/lib/utils/zod'
import { routes } from '@constants/routes'
import { formatDateShort } from '@utils/date-utils'
import { RequestErrorHandler } from '@api/types'
import { BuildingTypeCode } from '@api/endpoints/buildings/buildingTypes'
import {
  BuildingAddress,
  BuildingId,
  BuildingListItem,
  useDeleteBuilding,
  useGetBuildings
} from '@api/endpoints/buildings/houses'
import {
  ApartmentsCount,
  FloorNumber,
  LayoutTypeId,
  LayoutTypeName,
  useGetLayoutTypes
} from '@api/endpoints/buildings/layoutTypes'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useDebounce } from '@hooks/useDebounce'
import { useSnackbar } from 'notistack'
import { Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button, RouterButton } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { FormTextFieldStyled, useFormZod } from '@controls/Form'
import { Table, TableColumn } from '@controls/Table'
import { Text } from '@controls/Text'
import { BuildingsStyled } from '@pages/Admin/Buildings/style'
import { BuildingFormHistoryState } from './BuildingForm/BuildingForm'

type LayoutTypeItem = {
  layoutTypeId: LayoutTypeId
  buildingTypeCode: BuildingTypeCode
  name: LayoutTypeName
  floorsCount: FloorNumber
  apartmentsCount?: ApartmentsCount
  buildings: ReadonlyArray<BuildingListItem>
}

const BuildingsSearchSchema = z.object({
  address: BuildingAddress.optional()
})
type BuildingsSearchValues = z.infer<typeof BuildingsSearchSchema>

export const Buildings: React.FC = () => {
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()

  const onDeleteBuildingError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.building.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const form = useFormZod<BuildingsSearchValues>({
    schema: BuildingsSearchSchema
  })

  const address = form.watch('address')
  const debouncedAddress = useDebounce(address, 500)
  const validAddress = zodUndefinedIfInvalid(debouncedAddress, BuildingAddress)

  const deleteBuilding = useDeleteBuilding({ onError: onDeleteBuildingError })
  const getBuildings = useGetBuildings(validAddress, { onError: defaultErrorHandler })
  const getLayoutTypes = useGetLayoutTypes({ onError: defaultErrorHandler })

  const layoutsData: ReadonlyArray<LayoutTypeItem> = React.useMemo(() => {
    const layoutItems: ReadonlyArray<LayoutTypeItem> =
      getLayoutTypes.data
        ?.map((data) => ({
          layoutTypeId: data.id,
          buildingTypeCode: data.buildingType.code,
          name: data.name,
          floorsCount: data.floorsCount,
          apartmentsCount: data.apartmentsCount,
          buildings: getBuildings.data?.filter((b) => b.layoutTypeId == data.id) ?? []
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) ?? []

    if (debouncedAddress) {
      return layoutItems.filter((l) => l.buildings.length > 0)
    }

    return layoutItems
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBuildings.data, getLayoutTypes.data])

  const onRowClick = (row: BuildingListItem): void => {
    navigate(generatePath(routes.Admin.BuildingsEdit, { buildingId: row.id }))
  }

  const onDeleteBuildingClick =
    (id: BuildingId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteBuilding.mutate(id)
      }
    }

  const onRefreshClick: HtmlMouseButtonEventHandler = (e) => {
    e.preventDefault()
    void getLayoutTypes.refetch()
    void getBuildings.refetch()
  }

  const tableColumns: ReadonlyArray<TableColumn<BuildingListItem>> = React.useMemo(
    () => [
      {
        key: 'address',
        title: t('pages.admin.buildings.fields.address'),
        sortable: true
      },
      {
        key: 'facadeColor',
        title: t('pages.admin.buildings.fields.facade_color'),
        sortable: true
      },
      {
        key: 'roomsCount',
        title: t('pages.admin.buildings.fields.rooms_count'),
        sortable: true
      },
      {
        key: 'basePrice',
        title: t('pages.admin.buildings.fields.base_price'),
        sortable: true
      },
      {
        key: 'createdAt',
        title: t('pages.admin.buildings.fields.created'),
        sortable: true,
        render: (row) => formatDateShort(row.createdAt)
      },
      {
        key: null,
        title: null,
        render: (row) => (
          <ButtonWithConfirmation
            color="error"
            size="small"
            onConfirmResult={onDeleteBuildingClick(row.id)}
            confirmTitle={t('global.consent.delete.title')}
            confirmMessage={t('global.consent.delete.message')}
          >
            <Delete />
          </ButtonWithConfirmation>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <BuildingsStyled>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h1>{t('pages.admin.buildings.title')}</h1>
        <form onSubmit={form.handleSubmit(() => false)}>
          <FormTextFieldStyled form={form} name="address" label={t('pages.admin.buildings.search.by_address.title')} />
        </form>
      </Box>
      <Box display="flex" flexDirection="column" rowGap={4}>
        {layoutsData.length == 0 ? (
          <Box className="no-data" textAlign="center">
            <Text size="size-24" weight="light">
              {t('controls.table.messages.no_data_available')}
            </Text>
          </Box>
        ) : (
          layoutsData.map((data, index) => {
            const createState: BuildingFormHistoryState = { layoutTypeId: data.layoutTypeId }

            return (
              <Box key={index} className="building-section">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <div>
                    <div>
                      {t('pages.admin.buildings.layout_type.field.name') + ': '}
                      <strong>{data.name}</strong>
                    </div>
                    <div>
                      {t('pages.admin.buildings.layout_type.field.floors') + ': '}
                      <strong>{data.floorsCount}</strong>
                    </div>
                    <div>
                      {t('pages.admin.buildings.layout_type.field.apartments') + ': '}
                      <strong>{data.apartmentsCount ?? '-'}</strong>
                    </div>
                  </div>
                  <RouterButton to={{ pathname: routes.Admin.BuildingsNew, state: createState }}>
                    {t('pages.admin.buildings.buttons.create')}
                  </RouterButton>
                </Box>

                <Table
                  columns={tableColumns}
                  data={data.buildings ?? []}
                  onRowClick={onRowClick}
                  initialSortConfig={{ column: 'address', direction: 'ASC' }}
                />
              </Box>
            )
          })
        )}
      </Box>
      <Box display="flex" columnGap={2} mt={2}>
        <RouterButton to={routes.Admin.BuildingsNew}>{t('global.buttons.add')}</RouterButton>
        <Button onClick={onRefreshClick}>{t('global.buttons.refresh')}</Button>
      </Box>
    </BuildingsStyled>
  )
}
